import React from "react";
import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import SEO from "../components/seo";

export default () => (
  <Box>
    <SEO title="Thank you" />

    <Container maxWidth="md" style={{ paddingTop: "150px" }}>
      <Typography component="div">
        <Box
          textAlign="center"
          component="h1"
          m={1}
          fontFamily="DM Serif Text"
          fontWeight="700"
          color="#3d3d3d"
          fontSize="h2.fontSize"
        >
          Thank you for contacting us.
        </Box>
        <br />
        <Box color="#3d3d3d" component="p" textAlign="center">
          We have received your enquiry and will respond to you within 24 hours.
        </Box>
      </Typography>
    </Container>
  </Box>
);
